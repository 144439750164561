<template>
  <div class="recoveryDetail-container">
    <el-drawer class="recoveryDetail-drawer" title="客户详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="() => { this.drawer = false }">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm" >
            <div class="detail-title">
              <div class="title-text">{{ (customerInfo && customerInfo.name) || "--" }}</div>
              <div>
                <el-button class="common-screen-btn" @click="() => { recoverVisible = true }" type="primary">恢复</el-button>
              </div>
            </div>
            <div class="detail-content">
              <el-form-item
                v-if="editParams.filedName == 'areaId'"
                class="edit-input-container"
                prop="areaId" label="省市区:">
                <el-cascader ref="areaId" class="edit-input" placeholder="请选择" v-model="editRuleForm.areaId" @blur="() => {editCancelBtn('areaId')}" @change="(e) => {editSeaDetail('areaId')}" :props="props" filterable></el-cascader>
              </el-form-item>
              <div @click="() => { editBtn('areaId', customerInfo.areaId)}" v-if="editParams.filedName != 'areaId'" class="hoverEdit">
                省市区:
                <span class="editColor">{{ customerInfo && customerInfo.areaName || '--'}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'address'" class="edit-input-container" prop="address" label="详细地址:">
                <el-input class="edit-input" ref="address" placeholder="请输入内容" v-model="editRuleForm.address" @blur="() => {editCancelBtn('address')}">
                  <template slot="append"><div @click=" () => {editSeaDetail('address')}" class="cancel">确认</div></template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('address', customerInfo.address)}" v-if="editParams.filedName != 'address'" class="hoverEdit">
                详细地址:
                <span class="editColor">{{(customerInfo && customerInfo.address) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'level'" class="edit-input-container" prop="level" label="客户级别:">
                <el-select class="edit-input" ref="level" v-model="editRuleForm.level" placeholder="请选择" @blur="() => {editCancelBtn('level')}" @change="(e) => {editSeaDetail('level')}" filterable>
                  <el-option v-for="item in custDict['1']" :key="item.id + ''" :label="item.name + ''" :value="item.id + ''"></el-option>
                </el-select>
              </el-form-item>
              <div
                @click="() => {editBtn('level', customerInfo.level)}" v-if="editParams.filedName != 'level'" class="hoverEdit">
                客户级别:
                <span class="editColor">{{(customerInfo && customerInfo.levelName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'industry'" class="edit-input-container" prop="industry" label="所属行业:">
                <el-select class="edit-input" ref="industry" v-model="editRuleForm.industry" placeholder="请选择" @blur="() => {editCancelBtn('industry')}" @change="(e) => {editSeaDetail('industry')}" filterable>
                  <el-option v-for="item in custDict['6']" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div @click="() => {editBtn('industry', customerInfo.industry)}" v-if="editParams.filedName != 'industry'" class="hoverEdit">
                所属行业:
                <span class="editColor">{{(customerInfo && customerInfo.industryName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'source'" class="edit-input-container" prop="source" label="客户来源:">
                <el-select class="edit-input" ref="source" v-model="editRuleForm.source" placeholder="请选择" @blur="() => {editCancelBtn('source')}" @change="(e) => {editSeaDetail('source')}" filterable>
                  <el-option v-for="item in custDict['2']" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div @click="() => {editBtn('source', customerInfo.source)}" v-if="editParams.filedName != 'source'" class="hoverEdit" >
                客户来源:
                <span class="editColor">{{ (customerInfo && customerInfo.sourceName) || "--" }}</span>
              </div>
              <div @click="() => { projectShow() }" class="hoverEdit">
                <span class="noteName">客户划分:</span>
                <span class="editColor">{{(customerInfo && customerInfo.projectType) || "--"}}</span>
              </div>
              <div v-if="customerInfo.projectType == '项目'" @click="() => { projectShow() }" class="hoverEdit">
                <span class="noteName">项目名称:</span>
                <span class="editColor">{{(customerInfo && customerInfo.projectName) || "--"}}</span>
              </div>
              <div @click="() => { phoneWxShow() }" class="noteHoverEdit">
                <span class="noteName">联系方式:</span>
                <span class="editColor">{{(customerInfo && customerInfo.phoneWx) || "--"}}</span>
              </div>

            </div>
            <div class="borderLine"></div>
            <div class="data-content">
              <div class="data-title">联系信息</div>
              <div class="detail-content">
                <el-form-item
                  v-if="editParams.filedName == 'qq'"
                  class="edit-input-container"
                  prop="qq"
                  label="QQ:"
                >
                  <el-input
                   ref="qq"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.qq"
                    @blur="
                      () => {
                        editCancelBtn('qq');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editSeaDetail('qq');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('qq', customerInfo.qq);
                    }
                  "
                  v-if="editParams.filedName != 'qq'"
                  class="hoverEdit"
                >
                  QQ:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.qq) || "--"
                  }}</span>
                </div>
                <el-form-item
                  v-if="editParams.filedName == 'web'"
                  class="edit-input-container"
                  prop="web"
                  label="公司网址:"
                >
                  <el-input
                  ref="web"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.web"
                    @blur="
                      () => {
                        editCancelBtn('web');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editSeaDetail('web');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('web', customerInfo.web);
                    }
                  "
                  v-if="editParams.filedName != 'web'"
                  class="hoverEdit"
                >
                  公司网址:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.web) || "--"
                  }}</span>
                </div>
                <el-form-item v-if="editParams.filedName == 'mail'" class="edit-input-container" prop="mail" label="邮箱:">
                  <el-input class="edit-input" ref="mail" placeholder="请输入内容" v-model="editRuleForm.mail" @blur="() => {editCancelBtn('mail')}">
                    <template slot="append"><div @click="() => {editSeaDetail('mail')}" class="cancel">确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click=" () => { editBtn('mail', customerInfo.mail); } " v-if="editParams.filedName != 'mail'" class="hoverEdit">
                  邮箱:
                  <span class="editColor">{{(customerInfo && customerInfo.mail) || "--"}}</span>
                </div>
                <el-form-item v-if="editParams.filedName == 'note'" class="edit-input-container" prop="note" label="备注:" >
                  <el-input class="edit-input" ref="note" placeholder="请输入内容" v-model="editRuleForm.note" @blur=" () => { editCancelBtn('note') } ">
                    <template slot="append"><div @click=" () => {  editSeaDetail('note') } " class="cancel" >确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click=" () => { editBtn('note', customerInfo.note); }" v-if="editParams.filedName != 'note'" class="noteHoverEdit">
                  <span class="noteName">备注:</span>
                  <span class="editColor">{{ (customerInfo && customerInfo.note) || "--" }}</span>
                </div>
              </div>
            </div>
            <div class="borderLine"></div>
          </el-form>
          <div class="price-container">
            <div class="price-item">
              <div>累计应收金额（元）</div>
              <div>{{(Number(otherInfo.totalPrice)).toFixed(2)}}</div>
            </div>
            <div class="price-item">
              <div>累计收款金额（元）</div>
              <div>{{(Number(otherInfo.collectionPrice)).toFixed(2)}}</div>
            </div>
            <div class="price-item">
              <div>累计欠款金额（元）</div>
              <div>{{(Number(otherInfo.historyCollectionPrice)).toFixed(2)}}</div>
            </div>
          </div>
          <el-tabs class="tabsDetail" v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane :label="'店铺列表(' + pagination.total + ')'" name="1">
              <el-table class="common-table" :data="shopTableData">
                <el-table-column prop="shopName" label="店铺名称">
                  <template slot-scope="scope">
                      <el-button style="white-space: normal;text-align: left" type="text" size="small" @click="() => {
                      openNewWin(`/customer/company-shop-list?shopId=${scope.row.id}&shopName=${scope.row.shop_name}&from=customer`)
                      }">{{scope.row.shop_name || '--'}}</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="服务时间">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">
                      {{ `${DateTransform(scope.row.start_time)}\n${DateTransform(scope.row.end_time)}` }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="店铺服务状态">
                  <template slot-scope="scope">
                    <div v-if="scope.row.state == 0" class="common-tableStatus-red">服务终止</div>
                    <div v-if="scope.row.state == 1" class="common-tableStatus-green">正在服务</div>
                    <div v-if="scope.row.state == 2" class="common-tableStatus-blue">待服务</div>
                  </template>
                </el-table-column>
                <el-table-column prop="platform_name" label="所属平台" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="category_name" label="所属类目" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="businessPeopleName" label="运营负责人" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="create_name" label="创建人" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" :formatter="tableColumn"></el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="shopParams.pageSize"
                :current-page="shopParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"></el-pagination>
                <br/>
            </el-tab-pane>
            <el-tab-pane label="历史订单" name="2">
              <el-table class="common-table" :data="orderTable" :fit="true">
                <el-table-column label="订单编号" :formatter="tableColumn" width="140" fixed="left">
                  <template slot-scope="scope">
                    <div class="newLable">
                      <el-button style="white-space: normal;text-align: left" type="text" size="small" @click="() => {
                        openNewWin(`/customer/total-order?orderNo=${scope.row.orderNo}&orderId=${scope.row.id}&from=customer`)
                      }">{{scope.row.orderNo || '--'}}</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="orderName"
                  label="订单名称"
                  :formatter="tableColumn"
                  width="140px"
                ></el-table-column>
                <el-table-column
                  prop="shopNames"
                  label="所属店铺"
                  width="140px"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      v-if="scope.row.shopNames"
                      class="item"
                      effect="dark"
                      :content="scope.row.shopNames"
                      placement="bottom"
                    >
                      <div style="white-space: pre-line">
                        {{ commonLineFeed(scope.row.shopNames) }}
                      </div>
                    </el-tooltip>
                    <div v-else style="white-space: pre-line">
                      {{ commonLineFeed(scope.row.shopNames) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="orderStep"
                  label="服务阶段"
                  :formatter="tableColumn"
                  width="120px"
                ></el-table-column>
                <el-table-column label="服务时间" width="120px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">
                      {{
                        `${DateTransform(scope.row.startDate)}\n${DateTransform(scope.row.endDate)}`
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="订单状态" width="120px">
                  <template slot-scope="scope">
                    <div>
                      <div v-if="scope.row.status == 'process'" class="common-tableStatus-red">续签预警</div>
                      <div v-if="scope.row.status == 'setFail'" class="common-tableStatus-grey">续签失败</div>
                      <div v-if="scope.row.status == 'complete'" class="common-tableStatus-blue">续签成功</div>
                      <div v-if="scope.row.status == 'servicing'" class="common-tableStatus-green">正在服务</div>
                      <div v-if="scope.row.status == 'fail'" class="common-tableStatus-orange">续签超时</div>
                      <div v-if="scope.row.status == 'setDelete'" class="common-tableStatus-grey">订单作废</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="收款状态" width="120px">
                  <template slot-scope="scope">
                    <div v-if="scope.row.financialStatus">
                      <div v-if="scope.row.financialStatus == 'pending'" style="color: #F98787;">待销售催款</div>
                      <div v-if="scope.row.financialStatus == 'confirming'" style="color: #F09009;">待财务确认</div>
                      <div v-if="scope.row.financialStatus == 'complete'" style="color: #1890FF;">交易完成</div>
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>

                <el-table-column prop="originalPrice" label="订单原始价格" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="differencePrice" label="订单调价价格" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="price" label="应收金额" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="uncollectionAmount" label="未收金额" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="collectionAmount" label="实收金额" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="createName" label="销售负责人" :formatter="tableColumn" width="160px"></el-table-column>
                <el-table-column prop="createTime" label="创建人" :formatter="tableColumn" width="160px" ></el-table-column>
                <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn" width="180px" ></el-table-column>
                <el-table-column prop="note" label="备注" :formatter="tableColumn" width="160px" show-overflow-tooltip ></el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunOrder(e, 'pageSize')"
                @current-change="(e) => pageFunOrder(e)"
                style="text-align: center"
                :page-sizes="paginationOrder.pageSizes"
                :page-size="orderParams.pageSize"
                :current-page="orderParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationOrder.total"></el-pagination>
                <br/>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="4">
              <div class="detail-content">
                <div class="DisableEdit">客户负责人:<span class="common-clr-0a6">{{(adminInfo && adminInfo.chargeUserName) || "--"}}</span></div>
                <div class="DisableEdit">客户负责人所属部门：:<span class="common-clr-0a6">{{(adminInfo && adminInfo.chargeUserDepartName) || "--"}}</span></div>
                <div class="DisableEdit">创建人:<span class="common-clr-0a6">{{(customerInfo && customerInfo.createName) || "--"}}</span></div>
                <div class="DisableEdit">创建时间:<span class="common-clr-0a6">{{(customerInfo && customerInfo.createTime) || "--"}}</span></div>
                <div class="DisableEdit">最后修改人:<span class="common-clr-0a6">{{(adminInfo && adminInfo.nickName) || "--"}}</span></div>
                <div class="DisableEdit">最后修改时间:<span class="common-clr-0a6">{{(customerInfo && customerInfo.updateTime) || "--"}}</span></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="工作记录" name="about">
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog v-dialogDrag title="联系方式" :append-to-body="true" :visible.sync="phoneWxVisible" :close-on-click-modal="false" width="520px">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form :model="phoneWxRuleForm" ref="phoneWxRuleForm" :rules="phoneWxRules" label-width="120px" class="demo-ruleForm">
          <el-form-item v-for="(item, index) in phoneWxRuleForm.phoneArr" :key="index" :prop="`phoneArr[${index}].value`" :rules="item.type == 'phone' ? phoneWxRules.phone : phoneWxRules.wx">
            <template slot="label">
              <el-select v-model="item.type" style="width: 70%">
                <el-option label="手机" value="phone"></el-option>
                <el-option label="微信" value="wx"></el-option>
              </el-select>
            </template>
            <el-input class="dialog-input" v-model="item.value" maxLength="50" placeholder="请填写"></el-input>
            <span v-if="index == 0" class="el-icon-circle-plus-outline" @click="() => { addPhoneFun() }"></span>
            <span v-if="index != 0" class="el-icon-delete" @click="() => { deletePhoneFun(index) }"></span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="phoneWxVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => submitForm('phoneWxRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="客户划分" :append-to-body="true" :visible.sync="projectVisible" :close-on-click-modal="false" width="520px">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form :model="projectForm" ref="projectForm" :rules="projectRules" label-width="120px" class="demo-ruleForm">
          <el-form-item label="客户划分" prop="projectType">
            <el-select class="dialog-input" v-model="projectForm.projectType" placeholder="请选择" filterable >
              <el-option label="项目" :value="217" ></el-option>
              <el-option label="散号" :value="218" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="projectForm.projectType == 217" label="项目名称" prop="projectName">
            <el-input class="dialog-input" v-model="projectForm.projectName" maxLength="50" placeholder="请输入项目名称" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="projectVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => projectSubmitForm('projectForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="恢复客户" :append-to-body="true" :visible.sync="recoverVisible" :close-on-click-modal="false" width="520px">
        <span> 恢复客户后，客户将回到客户中心，确认恢复吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="recoverVisible = false">取 消</el-button>
            <el-button class="common-screen-btn" :loading="recoveryLoading" type="primary" @click="() => recoveryCustomer()">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { editSeaDetail, getShopList, getRenewalList, seaDetail, recoveryCustomer } from "../../../service/customer.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import { workLog, sysArea, sysAreaDetail } from "../../../service/common.js"
import { tableColumn, DateTransform, commonLineFeed } from "../../../utils/index.js"
export default {
  components: { CommonWorkLog },
  props: [
    "custDict",
  ],
  data() {
    let ruleAccount = (rule, value, callback) => {
      let reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (!reg.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let isEmaill = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("邮箱格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
        recoverVisible: false, // 恢复客户二次提醒
        recoveryLoading: false, // 删除客户loading
        drawer: false,
        loading: false, // loading
        customerInfo: '', // 客户信息
        adminInfo: '', // 系统信息
        otherInfo: {}, // 金额
        customerId: '', // 客户id
        commonLineFeed,
        DateTransform,
        tableColumn,
        activeTab: '1',
        orderTable: [],
        paginationOrder: {
            total: 0,
            pageSizes: [5, 10, 20, 50, 100],
        },
        orderParams: {
            page: 1,
            pageSize: 10
        },
        shopTableData: [],
        pagination: {
            total: 0,
            pageSizes: [5, 10, 20, 50, 100],
        },
        shopParams: {
            pageNum: 1,
            pageSize: 10
        },
        props: {
            lazy: true,
            lazyLoad: async (node, resolve) => {
            const { level, value } = node;
            let typeId
            if (level == 0) {
                typeId = 0
            } else {
                typeId = value
            }
            let resData = (await sysArea({id: typeId})).data
            let nodes = resData.map((item) => ({
                value: item.id + '',
                label: item.name,
                leaf: level >= 2
                }))
            resolve(nodes)
            }
        },
        followRecordList: [], // 工作日志
        filesList: [],
        phoneWxVisible: false,
        editParams: {
            filedName: "",
            filedValue: "",
        },
        activeName: "about", // tabs
        editRules: {
            mail: [{ validator: isEmaill, trigger: "change" }],
        },
        editRuleForm: {
            address: "",
            markingStatus: "",
            web: "",
            note: "",
            mail: "",
            qq: "",
            wx: "",
            phone: "",
            industryName: "",
            industry: "",
            source: "",
            level: "",
            seaName: "",
            seaType: "",
            name: "",
            projectName: '',
            projectType: ''
        },
        projectVisible: false,
        projectForm: {
            projectName: '',
            projectType: ''
        },
        projectRules: {
            projectType: [{ required: true, message: "请选择客户划分", trigger: "change" }],
            projectName: [{ required: true, message: "请填写项目名称", trigger: "change" }],
        },
        phoneWxRuleForm: {}, // 联系方式编辑
        phoneWxRules: { // 联系方式校验
            phone: [{ validator: ruleAccount, trigger: "change" },{ required: true, message: "请填写手机号", trigger: "change" }],
            wx: [{ required: true, message: "请填写微信号", trigger: "change" }],
        },
    };
  },
    methods: {
        async recoveryCustomer () { // 恢复客户
            this.recoveryLoading  = true
            let { code } = await recoveryCustomer({customerId: this.customerId, filedValue: 0, filedName: 'isRecycle'})
            this.recoveryLoading  = false
            if ( code == 200 ) {
                this.$message.success('恢复成功')
                this.$emit('resetBtn')
                this.drawer = false
                this.recoverVisible = false
            }
        },
        async getCustDeatil (id) { // 获取详情
            this.customerId = id
            this.loading = true
            this.drawer = true
            let { data } = await seaDetail({ customerId: id })
            if (data.customerDeatailVo && data.customerDeatailVo.areaId) {
                data.customerDeatailVo.areaId = data.customerDeatailVo.areaId.toString().split(",");
                data.customerDeatailVo.areaName = (await sysAreaDetail({ id: data.customerDeatailVo.areaId[data.customerDeatailVo.areaId.length - 1]})).data
                if (data.customerDeatailVo.phone) {
                    if (data.customerDeatailVo.wx) {
                    data.customerDeatailVo.phoneWx = data.customerDeatailVo.phone + ',' + data.customerDeatailVo.wx
                    } else {
                    data.customerDeatailVo.phoneWx = data.customerDeatailVo.phone
                    }
                } else {
                    data.customerDeatailVo.phoneWx = data.customerDeatailVo.wx
                }
            }
            this.customerInfo = data.customerDeatailVo || ""
            this.adminInfo = data.admin || ""
            this.otherInfo = {
                totalPrice: data.totalPrice || 0,
                collectionPrice: data.collectionPrice || 0,
                historyCollectionPrice: data.historyCollectionPrice || 0
            }
            this.workLog(id);
            this.getShopList(id);
            this.getRenewalList(id);
            this.loading = false
        },
        projectSubmitForm(formName) { // 编辑客户划分-项目
            let projectForm = {...this.projectForm};
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                this.loading = true
                await editSeaDetail({customerId: this.customerId, filedName: 'projectType', filedValue: projectForm.projectType});
                if (projectForm.projectType == 217 ) {
                    await editSeaDetail({customerId: this.customerId, filedName: 'projectName', filedValue: projectForm.projectName});
                }
                this.loading = false
                this.projectVisible = false
                this.getCustDeatil(this.customerId)
                } else {
                    return false;
                }
            })
        },
        projectShow() { // 编辑客户划分弹窗-项目
            let customerInfo = {...this.customerInfo}
            this.projectForm = {
                projectType: customerInfo.projectType == "项目" ? 217 : 218,
                projectName: customerInfo.projectName
            }
            this.projectVisible = true;
        },
        openNewWin(url) {
            window.open(url)
        },
        async getRenewalList (id) {  // 历史订单列表
            let orderParams = {...this.orderParams}
            orderParams.customerId = id
            orderParams.recycleState = 1
            let { data } = await getRenewalList(orderParams)
            this.orderTable = data.listData.list
            this.paginationOrder.total = data.listData.total;
        },
        pageFunOrder(e, type) { // 分页
            if (type == "pageSize") {
                this.orderParams.pageSize = e;
            } else {
                this.orderParams.page = e;
            }
            this.getRenewalList(this.customerId);
        },
        async getShopList (id) { // 店铺列表
            let shopParams = {...this.shopParams}
            shopParams.customerId = id
            shopParams.recycleState = 1
            let { data } = await getShopList(shopParams)
            this.shopTableData = data.records
            this.pagination.total = data.total;
            console.log(data)
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.shopParams.pageSize = e;
            } else {
                this.shopParams.pageNum = e;
            }
            this.getShopList(this.customerId);
        },
        submitForm(formName) { // 编辑联系方式
            let phoneArr = [...this.phoneWxRuleForm.phoneArr];
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                let phone = []
                let wx = []
                phoneArr.map((item) => {
                    if(item.type == 'phone') {
                    phone.push(item.value)
                    } else {
                    wx.push(item.value)
                    }
                })
                phone = phone.join(',')
                wx = wx.join(',')
                this.loading = true
                await editSeaDetail({customerId: this.customerId, filedName: 'phone', filedValue: phone});
                await editSeaDetail({customerId: this.customerId, filedName: 'wx', filedValue: wx});
                this.loading = false
                this.phoneWxVisible = false
                this.getCustDeatil(this.customerId)
                } else {
                return false;
                }
            });
        },
        phoneWxShow() { // 编辑联系方式弹窗
            let customerInfo = {...this.customerInfo}
            let phone = customerInfo.phone
            let wx = customerInfo.wx
            let newArr = []
            if (phone) {
                phone = phone.split(",")
                phone.map((item) => {
                newArr.push({type: 'phone', value: item})
                })
            }
            if (wx) {
                wx = wx.split(",")
                wx.map((item) => {
                newArr.push({type: 'wx', value: item})
                })
            }
            this.phoneWxRuleForm = {
                phoneArr: newArr
            };
            if (this.$refs["phoneWxRuleForm"]) this.$refs["phoneWxRuleForm"].resetFields();
            this.phoneWxVisible = true;
        },
        addPhoneFun() { // 添加一条目手机号
            this.phoneWxRuleForm.phoneArr.push({type: 'phone', value: ''})
        },
        deletePhoneFun(i) { // 删除一条目手机号
            this.phoneWxRuleForm.phoneArr = this.phoneWxRuleForm.phoneArr.filter((item, index) => {
                return index != i
            })
        },
        async workLog(id) { // 工作日志
            let resData = (await workLog({id: id, type: -1})).data
            if (resData && resData.length) {
                resData.map((item) => {
                if (item.value) {
                    item.value = JSON.parse(item.value)
                } else {
                    item.value = []
                }
                })
            }
            this.followRecordList = resData
        },
        downLoad(url) { // 下载文件
            window.location.href = url;
        },
        editSeaDetail(name) { // 确认-提交修改
            let params = {
                customerId: this.customerId,
                filedName: name,
                filedValue: this.editRuleForm[name],
            };
            if (this.editParams.filedName) {
                this.$refs["editRuleForm"].validateField(name, async (error) => {
                    if (!error) {
                        if (name == 'areaId') {
                        params.filedValue = params.filedValue.join(',')
                        }
                        this.loading = true
                        await editSeaDetail(params);
                        this.loading = false
                        this.getCustDeatil(this.customerId)
                        this.editParams = {
                            filedName: "",
                            filedValue: "",
                        };
                    } else {
                        return false;
                    }
                });
            }
        },
        editCancelBtn(name) { // 取消编辑
            setTimeout(() => {
                if (this.isShowInput == name) {
                this.editParams = {
                    filedName: "",
                    filedValue: "",
                };
                this.isShowInput = ''
                }
            }, 500)
        },
        editBtn(name, value) {
            this.$refs["editRuleForm"].validate(async (valid) => {
                if (valid) {
                // this.promiseFun(name, value)
                new Promise((resolve) => {
                    this.editParams = {
                    filedName: name,
                    filedValue: value,
                    };
                    this.editRuleForm[name] = value;
                    resolve()
                }).then(() => {
                    if (this.$refs[name]) {
                    this.$refs[name].focus()
                    }
                }).then(() => {
                    this.isShowInput = name
                })
                } else {
                return;
                }
            });
        },
        handleClick() {
            console.log(this.activeName);
        },
    },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus-outline, .el-icon-delete {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}
.recoveryDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .price-container {
    margin: 48px 0;
    width: 98%;
    border-radius: 4px;
    background: #F2F9FF;
    display: flex;
    justify-content: space-between;
    padding: 24px 52px;
    box-sizing: border-box;
    .price-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      // &:nth-of-type(1) {
      //   margin-right: 200px;
      // }
      div {
        &:nth-of-type(1) {
          color: #666;
          font-size: 13px;
        }
        &:nth-of-type(2) {
          color: #1890ff;
          font-size: 28px;
          line-height: 48px;
        }
      }
    }
  }
  .borderLine {
    position: relative;
    width: 100%;
    margin-top: 14px;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: #eaeaeb;
      height: 1px;
      width: 120%;
      left: -10%;
      top: 0;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .item-detail {
            color: #333;
            margin-top: 8px;
            vertical-align: middle;
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .title-text {
        font-size: 18px;
        margin-right: 16px;
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 220px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .noteHoverEdit {
        display: inline-flex;
        width: 580px;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99% center
            #f2f9ff;
        }
      }
      .hoverEdit {
        white-space:normal; word-break:break-all;
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        .editColor {
          color: #1890ff;
        }
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .data-content {
      .detail-content {
        margin-top: 16px;
      }
      .data-title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #000000a6;
        border-bottom: 1px dashed #ccc;
        margin-top: 16px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.itemmb{
  margin-bottom: 0;
}
</style>
